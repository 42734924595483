.ribbon {
    position: absolute;
    top: 0;
    left: -15px;
    background: #C8BC73;
    padding: 0 5px 0 15px;
    height: 24px;
    line-height: 24px;
    z-index: 2;
    font-size: 14px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    color: white
}

.ribbon::before {
    position: absolute;
    content: '';
    border: 15px solid #b7ab63;
    border-left: 0px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 10px solid transparent;
    left: 0;
    top: 100%;
}

.ribbon::after {
    position: absolute;
    content: '';
    border: 15px solid #C8BC73;
    border-right: 15px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 24px solid transparent;
    left: 100%;
    top: 0;
}

.pointer-bottom {
    position: relative;
}

.pointer-bottom:before {
    content: '';
    height: 0;
    width: 0;
    border: 10px solid #EEECF2;
    border-bottom: 0;
    border-left-color: transparent;
    border-right-color: transparent;
    position: absolute;
    z-index: 2;
    left: 10px;
    bottom: -10px;
}

.ribbon-payment {
    position: absolute;
    top: -27px;
    left: -31px;
    background: #00aabd;
    padding: 0 5px 0 15px;
    height: 24px;
    line-height: 24px;
    z-index: 2;
    font-size: 12px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    color: white
}

.ribbon-payment::before {
    position: absolute;
    content: '';
    border: 15px solid #03909f;
    border-left: 0px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 10px solid transparent;
    left: 0;
    top: 100%;
}

.ribbon-payment::after {
    position: absolute;
    content: '';
    border: 15px solid #00aabd;
    border-right: 15px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 24px solid transparent;
    left: 100%;
    top: 0;
}


