.pillars-list {
    position: relative;
    height: 30px; 
    margin-bottom: 15px;
}

.pillars-list > div {
    display: inline-block;
    vertical-align: top;
    min-height: 35px;
}

.pillars-list ul {
    list-style: none;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.pillars-list ul li {
    display: inline-block;
    width: 85px;
    height: 25px;
    line-height: 23px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}

.category-list li {
    background: #fbfbfb;
    margin-bottom: 10px;
    list-style: none;
    padding: 20px 10px;
}

.category-list li > span {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    margin-bottom: 10px;
    display: inline-block;
}

.category-list li ul li {
    position: relative;
    padding: 5px;
    width: 45%;
    box-shadow: rgb(0 0 0 / 10%) 0px 5px 15px;
    background: #fff;
    border-radius: 5px;
    margin-left: 3%;
    display: inline-block;
    margin-bottom: 12px;
    vertical-align: top;
    outline-style: solid;
    outline-width: 1px;
    padding-right: 40px;
}
.category-list-user-modal li {
    background: transparent;
    margin-bottom: 0;
    padding: 0;
}
.category-list-user-modal li > span {
    margin-bottom: 0;
}
.category-list li ul li.small_tag {
    padding: 1px 5px;
    width: auto;
    margin-left: 8px;
    margin-bottom: 8px;
}
@media (max-width: 500px){
    .pillars-list {
        height: 60px;
    }
    .pillars-list ul {
        left: 55%;
    }
    .pillars-list ul li {
        width: 80px;
        font-size: 0.790rem;
    }
    .category-list li ul li {
        width: 93%;
    }
  }
.tag-actions-box {
    display: inline-block;
    position: absolute;
    right: 3px;
    top: 3px;
}

.tag-actions-box img {
    display: inline-block;
    margin-left: 2px;
}

.tag-actions-box img:last-child {
    cursor: pointer;
}