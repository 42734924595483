/* Generated by Font Squirrel (http://www.fontsquirrel.com) on October 5, 2015 */



@font-face {
    font-family: 'grumpyblack36';
    src: url('grumpy_black36-webfont.eot');
    src: url('grumpy_black36-webfont.eot?#iefix') format('embedded-opentype'),
         url('grumpy_black36-webfont.woff2') format('woff2'),
         url('grumpy_black36-webfont.woff') format('woff'),
         url('grumpy_black36-webfont.ttf') format('truetype'),
         url('grumpy_black36-webfont.svg#grumpyblack36') format('svg');
    font-weight: normal;
    font-style: normal;
}
